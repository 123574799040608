<template>
  <div style="padding: 40px;" v-loading="loading">
    <h3>用户列表</h3>
    <div style="height: 600px;">
      <el-table :data="computed_data.slice( (this.curr_page - 1) * 10, this.curr_page * 10 )" style="width: 100%;">
        <el-table-column
            prop="uidNumber"
            label="ID"
            width="90">
        </el-table-column>
        <el-table-column
            prop="uid"
            label="用户名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="cn"
            label="姓名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="mail"
            label="邮箱">
        </el-table-column>
        <el-table-column
            prop="telephoneNumber"
            label="手机号">
        </el-table-column>
        <el-table-column
            align="right">
          <template slot="header">
            <input
                class="search-input"
                v-model="search_text"
                placeholder="输入关键字搜索"
            />
          </template>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-pagination
          layout="total, prev, pager, next"
          :total="computed_data.length"
          :page-size="10"
          :current-page.sync="curr_page"
      >
      </el-pagination>
    </div>
    <el-divider></el-divider>
    <h3>添加新用户</h3>
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="账号" style="margin-bottom: 20px;">
          <input type="text" style="display: none;"/>
          <el-input placeholder="请输入账号" v-model="form.uid" autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="密码" style="margin-bottom: 20px;">
          <input type="password" style="display: none;"/>
          <el-input type="password" placeholder="请输入密码" v-model="form.userPassword"
                    autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="姓" style="margin-bottom: 20px;">
          <el-input placeholder="请输入姓氏" v-model="form.sn"></el-input>
        </el-form-item>
        <el-form-item label="名" style="margin-bottom: 20px;">
          <el-input placeholder="请输入名字" v-model="form.givenName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" style="margin-bottom: 20px;">
          {{ form.sn + form.givenName }}
        </el-form-item>
        <el-form-item label="home" style="margin-bottom: 20px;">
          <el-input placeholder="请输入home路径" v-model="form.homeDirectory"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" style="margin-bottom: 20px;">
          <el-input placeholder="请输入邮箱地址" v-model="form.mail"></el-input>
        </el-form-item>
        <el-form-item label="手机号" style="margin-bottom: 20px;">
          <el-input placeholder="请输入手机号" v-model="form.telephoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="loginShell" style="margin-bottom: 20px;">
          <el-input placeholder="请输入loginShell" v-model="form.loginShell"></el-input>
        </el-form-item>

        <el-form-item style="margin-top: 30px;">
          <el-button type="primary" @click="submitForm">添加</el-button>
        </el-form-item>
      </el-form>
    </div>


    <el-dialog
        title="用户信息"
        :visible.sync="userDialogVisible"
        width="800px">

      <div style="height: 400px;" v-loading="user_info_loading">
        <div style="height: 100%; overflow-y: scroll; font-size: 16px; padding: 0 32px;"
             v-if="select_user_info != null">
          <el-form :model="select_user_info" label-width="150px">
            <el-form-item class="user-info-form-item" label="uidNumber">{{ select_user_info.uidNumber }}</el-form-item>
            <el-form-item class="user-info-form-item" label="gidNumber">{{ select_user_info.gidNumber }}</el-form-item>
            <el-form-item class="user-info-form-item" label="homeDirectory">{{
                select_user_info.homeDirectory
              }}
            </el-form-item>
            <el-form-item class="user-info-form-item" label="sn">{{ select_user_info.sn }}</el-form-item>
            <el-form-item class="user-info-form-item" label="uid">{{ select_user_info.uid }}</el-form-item>
            <el-form-item class="user-info-form-item" label="gecos">{{ select_user_info.gecos }}</el-form-item>
            <el-form-item class="user-info-form-item" label="cn">
              <el-input placeholder="cn" v-model="select_user_info.cn"></el-input>
            </el-form-item>
            <el-form-item class="user-info-form-item" label="mail">
              <el-input placeholder="mail" v-model="select_user_info.mail"></el-input>
            </el-form-item>
            <el-form-item class="user-info-form-item" label="loginShell">
              <el-input placeholder="loginShell" v-model="select_user_info.loginShell"></el-input>
            </el-form-item>
            <el-form-item class="user-info-form-item" label="telephoneNumber">
              <el-input placeholder="telephoneNumber" v-model="select_user_info.telephoneNumber"></el-input>
            </el-form-item>
            <el-form-item class="user-info-form-item" label="新密码">
                <el-input type="password" placeholder="userPassowrd" v-model="select_user_info.userPassword"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>


      <span slot="footer" class="dialog-footer">
            <el-button @click="userDialogVisible = false">关 闭</el-button>
            <el-button type="warning" @click="doUpdateUserInfo">修 改</el-button>
        </span>
    </el-dialog>

  </div>
</template>
<script>
import api from '../../api';

export default {
  data: () => ({
    loading: false,
    user_info_loading: false,

    curr_page: null,
    userDialogVisible: false,
    select_user_info: null,
    form: {
      uid: "",
      userPassword: "",
      sn: "",
      givenName: "",
      homeDirectory: "",
      mail: "",
      telephoneNumber: "",
      loginShell: "/bin/bash"
    },
    user_list: [],
    search_text: ""
  }),
  mounted() {
    this.loading = true;
    api.getUserList().then(res => {
      if (res.code == 0) {
        this.user_list = res.data;
      } else {
        this.$message.error(res.message);
      }
      this.loading = false;
    }).catch(err => {
      this.$message.error(err.toString());
      console.error(err);
      this.loading = false;
    });
  },
  methods: {
    submitForm() {
      if (this.form.uid != "" && this.form.sn != "" && this.form.givenName != "" && this.form.homeDirectory != "" && this.form.telephoneNumber != "" && this.form.loginShell != "") {
        this.loading = true;
        api.addUser({
          gidNumber: 100,
          homeDirectory: this.form.homeDirectory,
          sn: this.form.sn,
          givenName: this.form.givenName,
          cn: this.form.sn + this.form.givenName,
          uid: this.form.uid,
          gecos: this.form.uid,
          mail: this.form.mail,
          userPassword: this.form.userPassword,
          loginShell: this.form.loginShell,
          telephoneNumber: this.form.telephoneNumber,
          sshPublicKey: "",
        }).then(res => {
          if (res.code == 0) {
            this.user_list.push({
              uid: res.data.uid,
              cn: res.data.cn,
              mail: res.data.mail,
              telephoneNumber: res.data.telephoneNumber,
            });
            this.form = {
              uid: "",
              userPassword: "",
              sn: "",
              givenName: "",
              homeDirectory: "",
              mail: "",
              telephoneNumber: "",
              loginShell: "/bin/bash"
            };
            this.$message.success("创建成功");
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
        }).catch(err => {
          this.$message.error(err.toString());
          console.error(err);
          this.loading = false;
        });
      } else {
        this.$message.error("不允许有字段为空");
      }
    },
    handleEdit(index, row) {
      this.select_user_info = null;
      this.userDialogVisible = true;
      this.user_info_loading = true;
      api.getUserInfo(row.uid).then(res => {
        if (res.code == 0) {
          this.select_user_info = {
            uidNumber: res.data.uidNumber,
            gidNumber: res.data.gidNumber,
            homeDirectory: res.data.homeDirectory,
            sn: res.data.sn,
            givenName: res.data.givenName,
            cn: res.data.cn,
            uid: res.data.uid,
            gecos: res.data.gecos,
            mail: res.data.mail,
            loginShell: res.data.loginShell,
            telephoneNumber: res.data.telephoneNumber,
            userPassword: ""
          };
        } else {
          this.$message.error(res.message);
        }
        this.user_info_loading = false;
      }).catch(err => {
        this.$message.error(err.toString());
        console.error(err);
        this.user_info_loading = false;
      });
    },
    doUpdateUserInfo() {
      this.user_info_loading = true;
      api.updateUserInfo(this.select_user_info.uid, this.select_user_info.userPassword, this.select_user_info.loginShell, this.select_user_info.mail, this.select_user_info.telephoneNumber, this.select_user_info.cn, "1").then(res => {
        if (res.code == 0) {
          this.$message.success("修改成功！");
        } else {
          this.$message.error(res.message);
        }
        this.user_info_loading = false;
      }).catch(err => {
        this.$message.error(err.toString());
        console.error(err);
        this.user_info_loading = false;
      });
    }
  },
  watch: {
    "form.uid"(nw) {
      this.form.homeDirectory = "/home/" + nw;
    }
  },
  computed: {
    computed_data() {
      return this.user_list.filter((x) => {
        return x.uid.includes(this.search_text) || x.cn.includes(this.search_text) || x.uidNumber.includes(this.search_text);
      });
    }
  }
}
</script>
<style scoped>
.user-info-form-item {
  margin: 0;
  margin-bottom: 15px;;
}

.search-input {
  border: 1px solid #AAA;
  padding: .5em 1em;
  font-size: 1em;
  border-radius: .2em;
  outline: none;
}
</style>